import { vertexShader, fragmentShader } from './tube';
import { colorRed1Vec,  colorRed2Vec,  colorRed1FracVec,  colorRed2FracVec, } from './tube';

AFRAME.registerComponent("heat-to-greenhouse-tube", {
  schema: {
      flowSpeed: { default: 0.2 }, // Speed of flow
      fraction: { default: 0.5 }, // fraction variable for the "heat" (color) of the cable
  },
  init: function () {
      const radius = 0.2;
      const flowDirection = new THREE.Vector2(0, 1);
      const warmToGreenHouseMaterial = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0 },
          flowDirection: { value: flowDirection },
          flowSpeed: { value: this.data.flowSpeed },
          stripeWidth: { value: 0.1 }, // Adjust stripe width as needed
          opacity: { value: 0.8 },
          fraction: { value: this.data.fraction },
          color1: { value: colorRed1Vec },
          color2: { value: colorRed2Vec },
          colorFrac1: { value: colorRed1FracVec },
          colorFrac2: { value: colorRed2FracVec },
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
      });
      
      const startWarmToGreenHouse = new THREE.Vector3(-15.8, -6, 3);
      const endWarmToGreenhouse = new THREE.Vector3(-22, 0, 3);

      const warmToGreenhousePoints = [
        endWarmToGreenhouse,
        new THREE.Vector3(startWarmToGreenHouse.x - 6.2, startWarmToGreenHouse.y + 1, startWarmToGreenHouse.z),
        new THREE.Vector3(startWarmToGreenHouse.x - 6.2, startWarmToGreenHouse.y + 1, startWarmToGreenHouse.z),
        new THREE.Vector3(startWarmToGreenHouse.x - 5.2, startWarmToGreenHouse.y, startWarmToGreenHouse.z),
        new THREE.Vector3(startWarmToGreenHouse.x - 5.2, startWarmToGreenHouse.y, startWarmToGreenHouse.z),
        //new THREE.Vector3(startWarmToGreenHouse.x - 0.7, startWarmToGreenHouse.y, startWarmToGreenHouse.z),
        //new THREE.Vector3(startWarmToGreenHouse.x - 0.7, startWarmToGreenHouse.y, startWarmToGreenHouse.z),
        startWarmToGreenHouse
      ];
      const warmToGreenHouseCurve = new THREE.CatmullRomCurve3(warmToGreenhousePoints);
      const warmToGreenHouseTubeGeometry = new THREE.TubeGeometry(warmToGreenHouseCurve, 10, radius, 8, false);
      const warmToGreenHouseTubeMesh = new THREE.Mesh(warmToGreenHouseTubeGeometry, warmToGreenHouseMaterial);
      this.el.setObject3D("warmToGreenHouseTubeMesh", warmToGreenHouseTubeMesh);

      this.el.sceneEl.addEventListener("tick", this.tick.bind(this));
  },
   tick: function (time, deltaTime) {
      const dt = (deltaTime / 1000) * this.data.flowSpeed;
      // Update time uniforms for shader animation
      this.el.getObject3D("warmToGreenHouseTubeMesh").material.uniforms.time.value += dt;
      this.el.getObject3D("warmToGreenHouseTubeMesh").material.uniforms.fraction.value = this.data.fraction;
    },
});
